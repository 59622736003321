import { useMobileResolution } from 'hooks/useMobileResolution';
import { createContext, ReactNode, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { RouteProps, routes } from '../routes/routes';

export type NavItems = Pick<
  RouteProps,
  | 'id'
  | 'can'
  | 'allowedUsers'
  | 'blockedUsers'
  | 'name'
  | 'icon'
  | 'path'
  | 'sideBar'
  | 'isMobile'
  | 'isOnlyMobile'
>;
interface NavigationContextContexProps {
  navItems: Array<NavItems>;
}

const NavigationContext = createContext({} as NavigationContextContexProps);

const NavigationProvider = ({ children }: { children: ReactNode }) => {
  const navItems = routes
    .filter(item => item.isPrivate)
    .map(
      ({
        name,
        icon,
        path,
        can,
        allowedUsers,
        blockedUsers,
        id,
        sideBar,
        isMobile,
        isOnlyMobile,
      }) => ({
        id,
        name,
        icon,
        path,
        can,
        sideBar,
        isMobile,
        isOnlyMobile,
        allowedUsers,
        blockedUsers,
      }),
    );

  const isMobile = useMobileResolution();
  const { location, push } = useHistory();
  useEffect(() => {
    if (isMobile) {
      if (
        !(
          location.pathname.includes('shared_tracking') ||
          location.pathname.includes('device-test') ||
          location.pathname.includes('map')
        )
      ) {
        push('/map');
      }
    }
  }, [isMobile, location, push]);

  return (
    <NavigationContext.Provider
      value={{
        navItems,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};

function useNavigation() {
  const context = useContext(NavigationContext);

  if (!context) {
    throw new Error('useNavigation must be used within an NavigationProvider');
  }

  return context;
}

export { NavigationProvider, useNavigation };
