import { useAuth } from 'context/authProvider';
import { validateUserPermissions } from 'utils/validateUserPermissions';

type UseCanParams = {
  permissions?: string[];
  role?: string[];
  allowedUsers?: number[];
  blockedUsers?: number[];
};

export function useCan({
  permissions,
  role,
  allowedUsers,
  blockedUsers,
}: UseCanParams): boolean {
  const { user, isAuthenticated } = useAuth();

  if (allowedUsers && allowedUsers.length) {
    if (!allowedUsers.includes(user.id)) {
      return false;
    }
  }

  if (blockedUsers && blockedUsers.length) {
    if (blockedUsers.includes(user.id)) {
      return false;
    }
  }

  if (!isAuthenticated) {
    return false;
  }

  const userHasValidPermissions = validateUserPermissions({
    user,
    permissions,
    roles: role,
  });

  return userHasValidPermissions;
}
