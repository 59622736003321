import { ComponentType, lazy, ReactElement } from 'react';

import {
  MapOutlined,
  AssignmentInd,
  Business,
  SettingsRemote,
  Accessibility,
  People,
  Build,
  Assessment,
  SyncAlt,
  Folder,
  SettingsInputAntenna,
  AddBox,
} from '@material-ui/icons';

import { SharedTracking } from 'pages/SharedTracking';
import { VersionPage } from 'pages/VersionsPage';
import Login from 'pages/Login';

const Dashboard = lazy(() => import('pages/Dashboard'));
const MapScreen = lazy(() => import('pages/Map'));
const Company = lazy(() => import('pages/Company'));
const Client = lazy(() => import('pages/Client/index.js'));
const InstallersPage = lazy(() => import('pages/Installer/index'));
const Device = lazy(() => import('pages/Device/index.js'));
const UsersPage = lazy(() => import('pages/User/index'));
const RolesPage = lazy(() => import('pages/Role'));
const IntegrationsPage = lazy(() => import('pages/Integrations'));
const ReportsPage = lazy(() => import('pages/Reports'));
const DeviceManagement = lazy(() => import('pages/DeviceManagement'));
const DeviceTest = lazy(() => import('pages/Device/DeviceTest/NewDeviceTest'));
const DeviceTestMobile = lazy(() => import('pages/DeviceTestMobile'));
const ValidateLink = lazy(() => import('pages/ValidateLink'));

export interface RouteProps {
  id: number;
  name: string;
  path: string;
  component: ComponentType<{ hasMenu: boolean }>;
  isPrivate: boolean;
  icon?: ReactElement;
  hasMenu: boolean;
  can?: Array<string>;
  exact?: boolean;
  sideBar?: boolean;
  isMobile?: boolean;
  isOnlyMobile?: boolean;
  allowedUsers?: number[] | undefined;
  blockedUsers?: number[] | undefined;
}

export const routes: RouteProps[] = [
  {
    id: 1,
    name: 'login',
    path: '/',
    sideBar: true,
    component: Login,
    exact: true,
    hasMenu: false,
    isPrivate: false,
  },
  {
    id: 2,
    path: '/dashboard',
    sideBar: true,
    component: Dashboard,
    name: 'Painel',
    isPrivate: true,
    icon: <Assessment fontSize="small" />,
    hasMenu: true,
    can: [],
    isMobile: true,
  },
  {
    id: 3,
    path: '/map',
    sideBar: true,
    component: MapScreen,
    name: 'Mapa',
    isPrivate: true,
    icon: <MapOutlined fontSize="small" />,
    hasMenu: false,
    can: [],
    isMobile: true,
  },
  {
    id: 4,
    path: '/company',
    sideBar: true,
    component: Company,
    name: 'Cadastro empresa',
    isPrivate: true,
    icon: <Business fontSize="small" />,
    hasMenu: true,
    can: [
      'view.companies',
      'create.companies',
      'update.companies',
      'delete.companies',
    ],
  },
  {
    id: 5,
    path: '/client',
    sideBar: true,
    component: Client,
    name: 'Cadastro cliente',
    isPrivate: true,
    icon: <AssignmentInd fontSize="small" />,
    hasMenu: true,
    can: [
      'view.customers',
      'create.customers',
      'update.customers',
      'delete.customers',
    ],
  },
  {
    id: 6,
    path: '/device',
    sideBar: true,
    component: Device,
    name: 'Cadastro dispositivo',
    isPrivate: true,
    icon: <SettingsRemote fontSize="small" />,
    hasMenu: true,
    can: ['view.devices', 'create.devices', 'update.devices', 'delete.devices'],
    blockedUsers: [
      1000, // one_solutions
      62, // alan.dev
      10, // one
      1013, // comodato_adm
      16, // wotson
      17, // amanda
      19, // brenda
      20, // brunoalmeida
      21, // julia
      60, // thiago
      61, // brendon
      98, // juliana
      1028, // emanuelly
      1078, // paulovinicius
      1025, // amanda_zarv
      146, // brenda_zarv
      1057, // julia.carvalho@lwtecnologia.com.br
      147, // julia_zarv
    ],
  },
  {
    id: 7,
    path: '/user',
    sideBar: true,
    component: UsersPage,
    name: 'Usuários',
    isPrivate: true,
    icon: <People fontSize="small" />,
    hasMenu: true,
    can: ['view.users', 'create.users', 'update.users', 'delete.users'],
  },
  {
    id: 8,
    path: '/installer',
    sideBar: true,
    component: InstallersPage,
    name: 'Instaladores',
    isPrivate: true,
    icon: <Build fontSize="small" />,
    hasMenu: true,
    can: [
      'view.installers',
      'create.installers',
      'update.installers',
      'delete.installers',
    ],
  },
  {
    id: 9,
    path: '/role',
    sideBar: true,
    component: RolesPage,
    name: 'Perfil',
    isPrivate: true,
    icon: <Accessibility fontSize="small" />,
    hasMenu: true,
    isOnlyMobile: false,
    can: ['view.roles', 'create.roles', 'update.roles', 'delete.roles'],
  },
  {
    id: 10,
    path: '/shared_tracking/:token',
    sideBar: true,
    component: SharedTracking,
    name: 'Tracking',
    isPrivate: false,
    icon: <Accessibility fontSize="small" />,
    hasMenu: false,
    isOnlyMobile: false,
  },
  {
    id: 11,
    path: '/versions',
    component: VersionPage,
    name: 'VersionPage',
    isPrivate: true,
    hasMenu: false,
    sideBar: false,
    isOnlyMobile: false,
  },
  {
    id: 12,
    path: '/device-test-mobile',
    component: DeviceTestMobile,
    name: 'DeviceTestPage',
    isOnlyMobile: true,
    isPrivate: true,
    hasMenu: true,
    sideBar: true,
    isMobile: true,
    icon: <AddBox fontSize="small" />,
    can: ['view.devices', 'create.devices', 'update.devices', 'delete.devices'],
  },
  {
    id: 13,
    path: '/integrations',
    component: IntegrationsPage,
    name: 'Integrações',
    isOnlyMobile: false,
    isPrivate: true,
    hasMenu: true,
    sideBar: true,
    isMobile: false,
    icon: <SyncAlt fontSize="small" />,
    can: ['view.integrations'],
  },
  {
    id: 14,
    path: '/reports',
    component: ReportsPage,
    name: 'Relatórios',
    isOnlyMobile: false,
    isPrivate: true,
    hasMenu: true,
    sideBar: true,
    isMobile: false,
    icon: <Folder fontSize="small" />,
  },
  {
    id: 15,
    path: '/device-management',
    sideBar: true,
    component: DeviceManagement,
    name: 'Cadastro dispositivo',
    isPrivate: true,
    icon: <SettingsRemote fontSize="small" />,
    hasMenu: true,
    allowedUsers: [
      1000, // one_solutions
      62, // alan.dev
      10, // one
      1013, // comodato_adm
      16, // wotson
      17, // amanda
      19, // brenda
      20, // brunoalmeida
      21, // julia
      60, // thiago
      61, // brendon
      98, // juliana
      1028, // emanuelly
      1078, // paulovinicius
      1025, // amanda_zarv
      146, // brenda_zarv
      1057, // julia.carvalho@lwtecnologia.com.br
      147, // julia_zarv
    ],
    // can: [
    //   'view.devices',
    //   'update.devices',
    //   'delete.devices',
    //   'transfer_company.device',
    //   'transfer_installer.device',
    // ],
  },
  {
    id: 16,
    path: '/device-test',
    component: DeviceTest,
    name: 'Teste de dispositivo',
    isPrivate: true,
    hasMenu: true,
    sideBar: true,
    icon: <SettingsInputAntenna fontSize="small" />,
    can: ['view.devices', 'create.devices', 'update.devices', 'delete.devices'],
    allowedUsers: [
      1000, // one_solutions
      62, // alan.dev
      10, // one
      1013, // comodato_adm
      16, // wotson
      17, // amanda
      19, // brenda
      20, // brunoalmeida
      21, // julia
      60, // thiago
      61, // brendon
      98, // juliana
      1028, // emanuelly
      1078, // paulovinicius
      1025, // amanda_zarv
      146, // brenda_zarv
      1057, // julia.carvalho@lwtecnologia.com.br
      147, // julia_zarv
    ],
  },
  {
    id: 17,
    name: 'accessLink',
    path: '/access/:id',
    sideBar: true,
    component: ValidateLink,
    exact: true,
    hasMenu: false,
    isPrivate: false,
  },
];
