import { useCan } from 'hooks/useCan';
import { ReactNode } from 'react';

interface CanProps {
  children: ReactNode;
  permissions?: string[];
  roles?: string[];
  allowedUsers?: number[];
  blockedUsers?: number[];
}

export function Can({
  children,
  permissions,
  roles,
  allowedUsers,
  blockedUsers,
}: CanProps) {
  const userCanSeeComponent = useCan({
    allowedUsers,
    blockedUsers,
    permissions,
    role: roles,
  });
  if (!userCanSeeComponent) {
    return null;
  }

  return <>{children}</>;
}
