/* eslint-disable no-underscore-dangle */
/* eslint-disable import/prefer-default-export */
import {
  createContext,
  useContext,
  useReducer,
  useEffect,
  useCallback,
  useRef,
  useMemo,
  useState,
} from 'react';
import { useToast } from 'context/toastProvider.tsx';
import { ACTIONS, initialState, reducer } from 'reducers/devicesGrid.ts';
import { DeviceTrackerService } from 'services/deviceTracker';
import { PositionsService } from 'services/positions.ts';
import { communicationGridColumns } from 'config/configColumns/gridCommunication.tsx';
import { subHours } from 'date-fns';
import { useDisclosure } from 'hooks/useDisclosure.ts';
import { useGetFences } from 'pages/Map/api/getFences.ts';
import { chunkArray } from 'utils/chunkArray.ts';
import { useLocation } from 'react-router-dom';
import {
  addUserDevicesOnGrid,
  clearUserDevicesOnGrid,
  fetchUserDevicesOnGrid,
  removeUserDevicesOnGridByDeviceId,
} from 'pages/Map/api/userDevicesOnGrid.ts';
import { useAuth } from './authProvider.tsx';
import { deviceGridColumns } from '../config/configColumns/gridDevices.tsx';
import { eventGridColumns } from '../config/configColumns/gridEvents.tsx';
import { useGoogleMaps } from './mapProvider.tsx';

export const GridContext = createContext();

const GridProvider = ({ children }) => {
  const location = useLocation();

  const { addToast } = useToast();
  const { googleMapsApiProps } = useGoogleMaps();
  const polygons = useRef([]);

  const timer = useRef();
  const { user } = useAuth();
  const {
    isOpen: isFenceModalOpen,
    close: closeFenceModal,
    open: openFenceModal,
  } = useDisclosure();
  const [isCreatingFence, setIsCreatingFence] = useState(false);
  const [selectedPolygonToEdit, setSelectedPolygonToEdit] = useState(null);
  const [selectedFenceRow, setSelectedFenceRow] = useState({});
  const { data: fences, isFetching: isFetchingFences } = useGetFences(
    user.companyId,
  );
  const { addTraceOnMainMap } = useGoogleMaps();

  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    modalSelectedRow,
    fetchedDevices,
    gridSelectedRow,
    devicesInGrid,
    selectedEventType,
    traceOnGridMap,
    deviceIdTrace,
    deviceHandlingModalOpen,
  } = state;

  const { userSettings } = user;

  const customDeviceGridColumns = useMemo(() => {
    if (user.userSettings?.grid?.deviceGridColumns?.length) {
      return user.userSettings.grid.deviceGridColumns
        .map(v => deviceGridColumns.find(c => v === c.name))
        .filter(e => e);
    }
    return deviceGridColumns.filter(v => v.default);
  }, [user.userSettings?.grid?.deviceGridColumns]);

  const customEventGridColumns = useMemo(() => {
    const userEventGridColumns = user.userSettings?.grid?.eventGridColumns;
    if (userEventGridColumns?.length) {
      const data = userEventGridColumns
        .map(i => {
          const column = eventGridColumns.find(c => c.name === i);
          return column;
        })
        .filter(i => i);

      return data;
    }
    return eventGridColumns.filter(v => v.default);
  }, [user.userSettings?.grid?.eventGridColumns]);

  const communicationEventGridColumns = useMemo(() => {
    if (user.userSettings?.grid?.communicationGridColumns?.length) {
      return user.userSettings.grid.communicationGridColumns
        .map(v => communicationGridColumns.find(c => v === c.name))
        .filter(e => e);
    }
    return communicationGridColumns.filter(v => v.default);
  }, [user.userSettings?.grid?.communicationGridColumns]);

  const hasPermissionToSearchAllCompanies = user.id === 16; // Permissão somente para o usuário wotson no momento!

  const updateDeviceInfo = useCallback(async () => {
    const devices = Object.keys(sessionStorage)
      .filter(id => id.includes('@light_web_admin:device-'))
      .map(device => sessionStorage.getItem(device));

    const devicesChunk = chunkArray(devices, 100);

    if (!devices.length) return;

    try {
      const fetchedLastPositionsChunk = await Promise.all(
        devicesChunk.map(chunk => PositionsService.lastPositionByImeis(chunk)),
      );

      const fetchedLastPositions = fetchedLastPositionsChunk
        .map(i => i.data.payload)
        .flat();

      if (fetchedLastPositions && fetchedLastPositions.length) {
        dispatch({
          type: ACTIONS.INSERT_DEVICES_IN_GRID,
          devicesInGrid: fetchedLastPositions,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const fetchDeviceTrace = useCallback(
    async (imei, traceType, value) => {
      if (imei) {
        const now = new Date();
        const params = {
          imei,
          year: now.getFullYear(),
          month: now.getMonth() + 1,
        };
        if (traceType === 'hours') params.hours = value;
        if (traceType === 'positions') params.limitPositions = value;

        try {
          const response = await PositionsService.getTraceTimeSeries(params);

          const { payload } = response.data;

          addTraceOnMainMap(payload);

          dispatch({
            type: ACTIONS.ADD_FETCHED_TRACE_DATA,
            traceOnGridMap: payload,
          });
        } catch (error) {
          console.log('error trace', error);
        }
      } else {
        addTraceOnMainMap([]);
      }
    },
    [addTraceOnMainMap],
  );

  useEffect(() => {
    async function fetchAndPaginateUSerDevicesOnGrid(page = 1) {
      if (!user.id) {
        return;
      }
      try {
        const resp = await fetchUserDevicesOnGrid(page);
        if (resp.data.length) {
          resp.data.forEach(device => {
            sessionStorage.setItem(
              `@light_web_admin:device-${device.imei}`,
              device.imei,
            );
          });
          if (resp.hasNextPage) {
            fetchAndPaginateUSerDevicesOnGrid(resp.currentPage + 1);
          }
        }
      } catch (error) {
        console.log('fetchAndPaginateUSerDevicesOnGrid error', error);
      }
    }
    fetchAndPaginateUSerDevicesOnGrid(1);
  }, [user.id]);

  useEffect(() => {
    updateDeviceInfo();

    const ONE_MINUTE = 60 * 1000;

    timer.current = setInterval(() => {
      if (!deviceHandlingModalOpen && location.pathname === '/map') {
        updateDeviceInfo();
      }
    }, ONE_MINUTE);

    return () => {
      clearInterval(timer.current);
    };
  }, [updateDeviceInfo, deviceHandlingModalOpen, location.pathname]);

  useEffect(() => {
    if (!userSettings?.map?.traceEnabled) {
      return;
    }

    if (deviceIdTrace && traceOnGridMap.length && devicesInGrid.length) {
      const newTraceData = [...traceOnGridMap];

      const newTrace = devicesInGrid.find(d => d.deviceId === deviceIdTrace);

      if (!newTrace) {
        addTraceOnMainMap([]);
        return;
      }

      const lastTrace = traceOnGridMap[traceOnGridMap.length - 1];

      const lastTraceLat = lastTrace.latitude;
      const lastTraceLng = lastTrace.longitude;

      const newTraceLat = newTrace.latitude;
      const newTraceLng = newTrace.longitude;

      const newTraceDateToDate = subHours(
        new Date(newTrace.dateTime.replace(' ', 'T')),
        3,
      );

      const newTraceDateToTime = newTraceDateToDate.getTime();

      const lastTraceDateToTime = new Date(lastTrace.dateTime[0]).getTime();

      if (
        newTraceDateToTime === lastTraceDateToTime ||
        newTraceDateToTime < lastTraceDateToTime
      ) {
        return;
      }

      if (lastTraceLat === newTraceLat && lastTraceLng === newTraceLng) {
        if (newTraceData[newTraceData.length - 1].dateTime[0]) {
          const firstDate = newTraceData[newTraceData.length - 1].dateTime[0];
          newTraceData[newTraceData.length - 1].dateTime[1] = firstDate;
        }
        newTraceData[newTraceData.length - 1].dateTime[0] = newTraceDateToDate;
        newTraceData[newTraceData.length - 1].count += 1;
      } else {
        newTraceData.push({
          dateTime: [newTraceDateToDate],
          ignition: newTrace.ignition,
          latitude: newTrace.latitude,
          longitude: newTrace.longitude,
          speed: newTrace.speed,
          direction: newTrace.direction,
          voltage: newTrace.voltage,
          bateryPercent: newTrace.bateryPercent,
          satellite: newTrace.satellite,
          signal: newTrace.signal,
          count: 1,
        });
      }
      addTraceOnMainMap([...newTraceData]);

      dispatch({
        type: ACTIONS.ADD_FETCHED_TRACE_DATA,
        traceOnGridMap: newTraceData,
      });
    } else {
      addTraceOnMainMap([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devicesInGrid]);

  useEffect(() => {
    if (!userSettings?.map?.traceEnabled) {
      addTraceOnMainMap([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSettings?.map?.traceEnabled]);

  function handleClearGrid() {
    dispatch({ type: ACTIONS.CLEAR_GRID });

    Object.keys(sessionStorage)
      .filter(id => id.includes('@light_web_admin:device-'))
      .map(item => sessionStorage.removeItem(item));

    try {
      clearUserDevicesOnGrid();
    } catch (error) {
      console.log('clearUserDevicesOnGrid error', error);
    }
  }

  const clearSelectModalDevice = () => {
    dispatch({
      type: ACTIONS.SELECT_MODAL_DEVICE_ROW,
      modalSelectedRow: [],
    });
  };

  const handleAddDevicesInGrid = useCallback(() => {
    const selectedRows = modalSelectedRow.map(id =>
      fetchedDevices.find(item => id === item.id),
    );

    const noVehicle = selectedRows.some(i => !i.plate);
    const newDevices = selectedRows.filter(d => d.plate && d.imei);

    if (!newDevices.length) {
      addToast('warning', 'Selecione algum dispositivo!');
      return;
    }

    if (noVehicle) {
      addToast(
        'warning',
        'Não é possível adicionar ao mapa dispositivo sem veículo associado!',
      );
    }

    const newDeviceImeis = newDevices.map(device => device.imei);
    const newDeviceIds = newDevices.map(device => device.id);

    const newDevicesInGrid = [...devicesInGrid, ...newDevices];

    dispatch({
      type: ACTIONS.INSERT_DEVICES_IN_GRID,
      devicesInGrid: newDevicesInGrid,
    });

    newDeviceImeis.map(id =>
      sessionStorage.setItem(`@light_web_admin:device-${id}`, id),
    );

    addToast('success', `inserido(s)`);

    try {
      addUserDevicesOnGrid(newDeviceIds);
    } catch (error) {
      console.log('addUserDevicesOnGrid error', error);
    }

    updateDeviceInfo();
    clearSelectModalDevice();
  }, [
    addToast,
    fetchedDevices,
    modalSelectedRow,
    devicesInGrid,
    updateDeviceInfo,
  ]);

  const handleSelectModalDeviceRow = useCallback(
    id => {
      const selectedIndex = modalSelectedRow.indexOf(id);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(modalSelectedRow, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(modalSelectedRow.slice(1));
      } else if (selectedIndex === modalSelectedRow.length - 1) {
        newSelected = newSelected.concat(modalSelectedRow.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          modalSelectedRow.slice(0, selectedIndex),
          modalSelectedRow.slice(selectedIndex + 1),
        );
      }

      dispatch({
        type: ACTIONS.SELECT_MODAL_DEVICE_ROW,
        modalSelectedRow: newSelected,
      });
    },
    [modalSelectedRow],
  );

  function handleSelectGridDeviceRow(element) {
    const { latitude, longitude } = element;

    let currentDeviceCoords;

    if (latitude && longitude) {
      currentDeviceCoords = { lat: Number(latitude), lng: Number(longitude) };
    }

    dispatch({
      type: ACTIONS.SELECT_GRID_DEVICE_ROW,
      gridSelectedRow: element,
      currentDeviceCoords,
    });
  }

  const handleSelectDeviceInGrid = useCallback(
    (row, from = '') => {
      const { latitude, longitude, deviceId, imei, eventSlug, status } = row;

      let selectedDeviceIdTrace = null;
      if (from === 'device_grid' || from === 'communication_grid') {
        selectedDeviceIdTrace = deviceId || null;
        if (userSettings?.map?.traceEnabled) {
          fetchDeviceTrace(
            imei || null,
            userSettings.map?.traceType || 'positions',
            userSettings.map?.traceValue || 20,
          );
        }
      }

      const currentDeviceCoords =
        latitude && longitude
          ? { lat: Number(latitude), lng: Number(longitude) }
          : null;

      if (googleMapsApiProps?.map) {
        if (currentDeviceCoords) {
          googleMapsApiProps.map?.setZoom(15);
          googleMapsApiProps.map?.setCenter(currentDeviceCoords);
        } else {
          googleMapsApiProps.map?.setZoom(8);
        }
      }

      dispatch({
        type: ACTIONS.SELECT_DEVICE_ROW_IN_GRID,
        selectedRowInGrid: { ...row, imei: imei || null },
        deviceId: deviceId || null,
        imei: imei || null,
        deviceIdTrace: selectedDeviceIdTrace,
        eventSlug: status === 'pending' ? null : eventSlug,
        temporaryInGrid: from === 'device_grid' ? {} : row,
      });
    },
    [
      fetchDeviceTrace,
      googleMapsApiProps?.map,
      userSettings?.map?.traceEnabled,
      userSettings?.map?.traceType,
      userSettings?.map?.traceValue,
    ],
  );

  const handleRemoveDevicesFromGrid = useCallback(
    selectedRow => {
      if (!selectedRow?.id) {
        addToast('warning', 'Selecione um dispositivo.');
        return;
      }

      const newDeviceArray = devicesInGrid.filter(
        item => item?.id !== selectedRow.id,
      );

      dispatch({
        type: ACTIONS.REMOVE_DEVICES_FROM_GRID,
        devicesInGrid: newDeviceArray,
      });

      sessionStorage.removeItem(`@light_web_admin:device-${selectedRow.id}`);

      try {
        removeUserDevicesOnGridByDeviceId(selectedRow.deviceId);
      } catch (error) {
        console.log('removeUserDevicesOnGridByDeviceId error', error);
      }
    },
    [devicesInGrid, addToast],
  );

  function handleSelectAllModalDeviceRows(devices) {
    dispatch({
      type: ACTIONS.SELECT_MODAL_DEVICE_ROW,
      modalSelectedRow: devices,
    });
  }

  function handleSelectAllGridDeviceRows(devices) {
    dispatch({
      type: ACTIONS.SELECT_GRID_DEVICE_ROW,
      gridSelectedRow: devices,
    });
  }

  function toggleSearchModal() {
    dispatch({ type: ACTIONS.TOGGLE_SEARCH_MODAL });
    dispatch({ type: ACTIONS.SELECT_MODAL_DEVICE_ROW, modalSelectedRow: [] });
  }

  function handleOpenInfoWindow(index) {
    dispatch({ type: ACTIONS.OPEN_INFOWINDOW, infoWindowIndex: index });
  }

  function handleCloseInfoWindow() {
    dispatch({ type: ACTIONS.CLOSE_INFOWINDOW });
  }

  async function getTrackerInfo(column, payload) {
    const resp = await DeviceTrackerService.search({
      column: column || 'imei',
      payload: payload || '',
      company_id: user.companyId,
      search_all_companies: hasPermissionToSearchAllCompanies,
    });

    const { payload: respPayload } = resp?.data || {};

    const formattedData = respPayload.map(({ chip, vehicle, ...device }) => ({
      ...device,
      deviceId: device.imei,
      plate: vehicle?.plate,
      vin: vehicle?.vin,
      name: vehicle?.client?.name,
      iccid: chip?.iccid,
    }));
    return formattedData;
  }
  // eslint-disable-next-line consistent-return
  async function handleSearch(column, payload) {
    let _column = column;
    let _payload = payload;

    if (!_payload && _column !== 'listAll') return;

    if (column === 'plate' && payload.split(' ').length > 1) {
      _column = 'plates';
      _payload = payload.split(' ');
    }

    if (column === 'imei' && payload.split(' ').length > 1) {
      _column = 'imeis';
      _payload = payload.split(' ');
    }

    dispatch({
      type: ACTIONS.FETCH_DEVICES,
    });

    try {
      dispatch({
        type: ACTIONS.SUCCESS_FETCHED_DEVICES,
        fetchedDevices: await getTrackerInfo(_column, _payload),
      });
    } catch (error) {
      console.log(error);
      dispatch({ type: ACTIONS.ERROR_FETCHED_DEVICES });
      addToast('error', error.response?.data?.message);
    }
  }

  function toggleDeviceHandlingModal() {
    dispatch({ type: ACTIONS.TOGGLE_DEVICE_HANDLING_MODAL });
  }

  function closeDeviceHandlingModal() {
    dispatch({ type: ACTIONS.CLOSE_DEVICE_HANDLING_MODAL });
  }

  function handleChangeSelectedModalTabIndex(_, index) {
    dispatch({
      type: ACTIONS.CHANGE_SELECTED_MODAL_TAB_INDEX,
      selectedModalTabIndex: index,
    });
  }

  function handleChangeGridTabIndex(index) {
    dispatch({
      type: ACTIONS.CHANGE_SELECTED_GRID_TAB_INDEX,
      selectedGridTabIndex: index,
    });
  }

  const handleSelectDeviceToHandle = useCallback(() => {
    dispatch({
      type: ACTIONS.SELECT_DEVICE_TO_HANDLE,
      selectedDeviceToHandle: gridSelectedRow,
    });

    if (!gridSelectedRow) {
      addToast('warning', 'Selecione um dispositivo.');
    }
  }, [gridSelectedRow, addToast]);

  const openDeviceHandlePage = useCallback(tabIndex => {
    dispatch({ type: ACTIONS.TOGGLE_DEVICE_HANDLING_MODAL, tabIndex });
  }, []);

  const fetchDeviceInfoByImei = useCallback(
    async imei => {
      dispatch({ type: ACTIONS.FETCH_DEVICE_INFO, imei });
      try {
        const resp = await DeviceTrackerService.getDeviceInfoByImei({
          imei,
          address: true,
        });

        const { payload } = resp.data;
        dispatch({
          type: ACTIONS.SUCCESS_FETCHED_DEVICE_INFO,
          fetchedDeviceInfo: payload,
        });
      } catch (error) {
        dispatch({
          type: ACTIONS.ERROR_FETCHED_DEVICE_INFO,
        });
        addToast('error', 'Algo deu errado');
      }
    },
    [addToast],
  );

  function handleChangeSelectedEventType(eventType, checked) {
    const newSelectedEventType = [...selectedEventType];
    if (checked) {
      newSelectedEventType.push(eventType);
    } else {
      const index = newSelectedEventType.findIndex(type => type === eventType);
      newSelectedEventType.splice(index, 1);
    }
    dispatch({
      type: ACTIONS.CHANGE_SELECTED_EVENT_TYPE,
      newSelectedEventType,
    });
  }

  return (
    <GridContext.Provider
      value={{
        handleSelectModalDeviceRow,
        handleAddDevicesInGrid,
        handleRemoveDevicesFromGrid,
        handleClearGrid,
        handleSelectAllModalDeviceRows,
        handleSelectGridDeviceRow,
        handleSelectDeviceInGrid,
        handleSelectAllGridDeviceRows,
        toggleSearchModal,
        handleOpenInfoWindow,
        handleCloseInfoWindow,
        state,
        handleSearch,
        toggleDeviceHandlingModal,
        closeDeviceHandlingModal,
        handleSelectDeviceToHandle,
        handleChangeSelectedModalTabIndex,
        handleChangeGridTabIndex,
        openDeviceHandlePage,
        fetchDeviceInfoByImei,
        handleChangeSelectedEventType,
        customDeviceGridColumns,
        customEventGridColumns,
        communicationEventGridColumns,
        isFenceModalOpen,
        closeFenceModal,
        openFenceModal,
        isCreatingFence,
        setIsCreatingFence,
        selectedPolygonToEdit,
        setSelectedPolygonToEdit,
        polygons,
        selectedFenceRow,
        setSelectedFenceRow,
        fences,
        isFetchingFences,
      }}
    >
      {children}
    </GridContext.Provider>
  );
};
function useGrid() {
  const context = useContext(GridContext);

  if (!context) {
    throw new Error('useGrid must be used within an GridPovider');
  }

  return context;
}

export { GridProvider, useGrid };
